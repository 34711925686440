
import React, { useEffect, useState } from 'react';
import { HStack,Flex } from '@chakra-ui/react';
import Card from '../../../componunts/Card';
import { format } from 'date-fns'
import { FaUserCheck } from "react-icons/fa6";
import { TbReportAnalytics } from "react-icons/tb";
import { sevenDaysMeet,TotalOnsites,datewisereport  } from '../../../request';
import { Navigate } from 'react-router-dom';

const TileBar = () => {

    let [today, setToday] = useState(format(new Date(), 'yyyy-MM-dd'));
    let [seventhday,setSeventhday] = useState(format(new Date(), 'yyyy-MM-dd'));
    let [onsites,setOnsites] = useState(0)
    let [meetings,setMeetings] = useState([]);
    let [sevendysmeet,setSevendaysmeet] = useState([]);
    let [total,setTotal] = useState(0)

    useEffect(() => {
      try {
        TotalOnsites(today).then(res =>{
          if(res.status === 200){
            setOnsites(res.data.list)
          }else{
            Navigate('/signin')
          }
        })
      } catch (err) {
        Navigate('/signin')
      }
  }, [today]);

    useEffect(()=>{
        try{
            datewisereport(today).then(res =>{
              // console.log(res.data.response)
              setTotal(res.data.response)
              let newdata = res.data.response.filter((ele)=>ele.checkin_time !== '-')
              setMeetings(newdata)
              
            })
        }catch(err){
          Navigate('/signin')
        }
    },[today]);

    useEffect(()=>{
        try{
          sevenDaysMeet().then(res =>setSevendaysmeet(res.data.list))
        }catch(err){
          console.log(err)
        }
      },[]);
     
  return (
     <Flex justifyContent={{base:'space-evenly',md:'left'}} w='100%' gap='10px' p='10px'>
         <Card logo={<FaUserCheck size='30px' color='white' />} title={`Today's Attendance`} period={today} meetings={meetings} total={total} />
         <Card logo={<TbReportAnalytics size='30px' color='white' />} title={`Last 7-Days Meetings`} period={today} seventhday={seventhday} meetings={sevendysmeet}/>
         <Card title={`Today's Outside Meetings`} period={today} meetings={onsites}/>
     </Flex>
  )
}

export default TileBar