import React from 'react';
import { Button } from '@chakra-ui/react';
import { saveAs } from 'file-saver';
import { FaFileDownload } from "react-icons/fa";

const ExportBtn = ({ data, filename }) => {

    const convertToCSV = (objArray) => {
        const headers = Object.keys(objArray[0]).join(',') + '\n';
        const csvRows = objArray.map((obj) =>
            Object.values(obj).join(',') + '\n'
        );
        return headers + csvRows.join('');
    };

    const handleDownload = async () => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, filename);
    };

    return (
        <Button w={{base:'150px',md:'120px'}}rightIcon={<FaFileDownload size={'20px'} />} colorScheme='teal' onClick={handleDownload}>Export</Button>
    )
}

export default ExportBtn