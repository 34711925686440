import React from 'react';
import { createContext } from 'react';

const AuthContext = createContext()

const Authantication = ({ children }) => {

  const loginAuth = (user) => {
    console.log(user)
  }

  const logoutAuth = () => {
     localStorage.removeItem('user')
  }


  return (
    <AuthContext.Provider value={{ loginAuth, logoutAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, Authantication };