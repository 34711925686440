import React, { useEffect, useState } from 'react';
import { Box, Select, Text } from '@chakra-ui/react';
// import { getAllUser } from '../Pages/request';
import { getAllBDM } from '../request';

const Dropdown = ({ handelUser,userID,singleDat}) => {

    let [alluser, setAlluser] = useState([])

    const getusers = async () => {
        try {
            let data = await getAllBDM()
            if (data.status === 200) {
                setAlluser(data.data.list)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getusers()
    }, [singleDat])

    return (
        <Box p='3px'>
            <Text as='b' fontSize='12px'> Select User</Text>
            <Select size='sm' onChange={(e) => handelUser(e.target.value)}>
                <option value='clear'>All</option>
                {
                    alluser && alluser.map((ele) => (
                        <option key={ele.id} value={ele.id}>{ele.name}</option>
                    ))
                }
            </Select>
        </Box>
    )
}

export default Dropdown