

import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import {
    Box, Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Flex, Text
} from '@chakra-ui/react';
import { MdDateRange } from "react-icons/md";
const Datepicker = ({ date, handelDate, handelTimeline, start, end }) => {

    // const handelclick = () => {
    //     handelTimeline();

    // }

    //    console.log(date)
    return (
        // <Menu closeOnSelect={false}>
        //     <MenuButton as={Button} bg='teal.400' p='10px' borderRadius='3px'>
        //       {start} to {end}
        //     </MenuButton>
        //     <MenuList>
        //         <MenuItem>
        //             <Box bg='teal.400' p='5px' w='fit-content'>
        //                 <DateRangePicker
        //                     onChange={item => handelDate([item.selection])}
        //                     // showSelectionPreview={true}
        //                     // moveRangeOnFirstSelection={false}
        //                     // months={1}
        //                     ranges={date}
        //                 // direction="horizontal"
        //                 />
        //                 <Box mt='10px'>
        //                     <Button colorScheme='teal' size='md' onClick={handelclick}>Apply</Button>
        //                 </Box>
        //             </Box>
        //         </MenuItem>
        //     </MenuList>
        // </Menu>
        <Box>
            <Menu>
                <MenuButton fontSize='12px' fontWeight='bold' p='10px' pl='10px' pr='10px' w='fit-content' borderRadius='3px' bg='teal.400'>
                    <Flex gap='5px'>
                        <MdDateRange fontSize={'30px'} />
                        {start === '' || end === '' ? <Text>Select Date</Text> : <Flex gap='10px' align="center">  <Text as='b'>{end} To {start}</Text></Flex>}
                    </Flex>
                </MenuButton>

                <MenuList>

                    <DateRangePicker
                        onChange={item => handelDate([item.selection])}
                        ranges={date}
                        // scroll={true}
                        direction={'vertical'}
                        moveRangeOnFirstSelection={false}
                    />

                    <MenuItem bg='blue.300' w='fit-content' ml='10px' onClick={handelTimeline}>
                        Apply
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    )
}

export default Datepicker