import React, { useState, useEffect, useContext } from 'react';
import {
    Box, Text, Flex, Tabs, TabList, TabPanels, Tab, TabPanel, Spacer, Button
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import Datepicker from '../../componunts/datepicker/Datepicker';
import Dropdown from '../../componunts/Dropdown';
import TileBar from './componunts/TileBar';
import Navbar from '../../componunts/navbar/Navbar';
import { addDays, format } from 'date-fns';
import { AuthContext } from '../auth/authprovider/Authantication';
import { useNavigate } from 'react-router-dom';
import { SingleUserReport, datewisereport, MeetingInfo } from '../../request';
import SingleDatePicker from '../../componunts/datepicker/SingleDatePicker';
import ExportBtn from '../../componunts/ExportBtn/ExportBtn';
import Loader from '../../componunts/loading/Loader';
import ViewModal from '../../componunts/modal/ViewModal';
import MeetingTable from './componunts/MeetingTable';


const Home = () => {
    let [userData, setUserData] = useState([]);
    let [Loading, setLoading] = useState(false);
    let [userID, setUserID] = useState('');
    let [singleuser, setSingleuser] = useState([]);
    let [date, setDate] = useState(new Date());
    let [singleDate, setSingleDate] = useState(new Date());

    let Navigate = useNavigate();

    const { logoutAuth } = useContext(AuthContext);
    let user = JSON.parse(localStorage.getItem('user')) || {};

    const [mydate, setMyDate] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }
    ]);
    let [start_date, setStart_date] = useState(format(mydate[0].startDate, 'yyyy-MM-dd').toString());
    let [to_date, setTo_date] = useState(format(mydate[0].endDate, 'yyyy-MM-dd').toString());


    const handelDate = (val) => {
        setMyDate(val)
    };

    const handelTimeline = () => {
        setStart_date(format(mydate[0].startDate, 'yyyy-MM-dd').toString())
        setTo_date(format(mydate[0].endDate, 'yyyy-MM-dd').toString())
    };

    const handelUser = (val) => {
        if (val === userID) {
            return;
        };
        setUserID(val)
    };


    // const FindUser = async (val) => {
    //     if (val === userID) {
    //         return
    //     };
    //     setLoading(true)
    //     if (val === 'clear') {
    //         getdata_datewise()
    //         setLoading(false)
    //         return
    //     };
    //     let newData = await datewisereport(format(singleDate, 'yyyy-MM-dd').toString());
    //     let findUser = newData.data.response.filter((ele) => ele.id === val);
    //     setUserData(findUser)
    //     setLoading(false)
    // };

    // const ShowInfo = async(date,id) => {
    //     try{
    //        let data = await MeetingInfo(date,id);
    //        if(data.status === 200){
    //         console.log(data.data.list)
    //        }else{
    //         console.log(data)
    //        }
    //     }catch(err){
    //         console.log(err)
    //     }
    // };

    const checkinTimeMarking = (time, BM) => {
        time = time.split(':').map(Number);
        let flag = false;
        if (time[0] > BM || (time[0] === BM && (time[1] > 0 || time[2] > 0))) {
            flag = true;
        };
        return flag
    };

    const WorkingHrsMarking = (time) => {
        time = time.split(':').map(Number);
        let flag = false;
        if (time[0] < 6) {
            flag = true;
        };
        return flag
    };



    function getTimeDifference(time1, time2) {

        const parseTime = (time) => {
            const [hours, minutes, seconds] = time.split(':').map(Number);
            const date = new Date();
            date.setUTCHours(hours, minutes, seconds);
            return date;
        };

        const date1 = parseTime(time1);
        const date2 = parseTime(time2);

        const timeDifferenceMs = date1 - date2;

        const hours = Math.floor(timeDifferenceMs / 3600000);
        const minutes = Math.floor((timeDifferenceMs % 3600000) / 60000);
        const seconds = Math.floor((timeDifferenceMs % 60000) / 1000);
        // console.log( hours, minutes, seconds)
        const formattedTimeDifference = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
        // console.log(formattedTimeDifference)
        return formattedTimeDifference;
        // console.log( hours, minutes, seconds)
        // return(`${hours}:${minutes}:${seconds}`)
    };

    const padZero = (num) => (num < 10 ? `0${num}` : num);

    const columns = [
        {
            name: <Text as='b' fontSize='15px'>BDM Name</Text>,
            selector: row => <Text fontSize='15px'>{row.bdm_name}</Text>
            ,
            // sortable: true,
        },
        {
            name: <Text as='b' fontSize='15px'>First-Checkin Time</Text>,
            selector: row => <Text fontSize='15px' color={checkinTimeMarking(row.checkin_time, 10) ? 'red' : ''}>{row.checkin_time}</Text>,
            // sortable: true,
        },
        {
            name: <Text as='b' fontSize='15px'>Last-checkout Time</Text>,
            selector: row => <Text fontSize='15px'>{row.checkout_time}</Text>,
            // sortable: true,
        },
        // {
        //     name: <Text as='b' fontSize='15px'>Total Office Hrs</Text>,
        //     selector: row => <Text fontSize='15px'>{row.office_working_hours}</Text>,
        //     sortable: true,
        // },
        // {
        //     name: <Text as='b' fontSize='15px'>Total Meeting Hrs</Text>,
        //     selector: row => <Text fontSize='15px'>{row.meeting_hours}</Text>,
        //     sortable: true,
        // },

        {
            name: <Text as='b' fontSize='15px'>Total Working Hrs</Text>,
            //Two Chages For function getTimeDifference
            // selector: row => <Text fontSize='15px' color={WorkingHrsMarking(getTimeDifference(row.checkout_time, row.checkin_time))?'red':''} >{row.checkout_time !== '-' || row.checkin_time !== '-'  ?getTimeDifference(row.checkout_time, row.checkin_time) : '-'}</Text>,
            selector: row => <Text color={WorkingHrsMarking(row.totalWorkingHour_h) ? 'red' : ''} fontSize='15px'>{row.totalWorkingHour_h}</Text>,
            // sortable: true,
        }
    ];


    let colm = [
        {
            name: <Text as='b' fontSize='15px' >Date</Text>,
            selector: row => <Text fontSize='15px'>{row.date}</Text>,
            // sortable: true

        },
        {
            name: <Text as='b' fontSize='15px'>BDM Name</Text>,
            selector: row => <Text fontSize='15px'>{row.bdm_name}</Text>
            ,
            // sortable: true,
        },
        {
            name: <Text as='b' fontSize='15px'>First-Checkin Time</Text>,
            selector: row => <Text fontSize='15px'>{row.checkin_time}</Text>,
            // sortable: true,
        },
        {
            name: <Text as='b' fontSize='15px'>Last-checkout Time</Text>,
            selector: row => <Text fontSize='15px'>{row.checkout_time}</Text>,
            // sortable: true,
        },
        // {
        //     name: <Text as='b' fontSize='15px'>Total Office Hrs</Text>,
        //     selector: row => <Text fontSize='15px'>{row.office_working_hours}</Text>,
        //     // sortable: true,
        // },
        {
            name: <Text as='b' fontSize='15px'>Total Meeting Hrs</Text>,
            selector: row => <Text fontSize='15px'>{row.meeting_hours}</Text>,
            // sortable: true,
        },
        // {
        //     name: <Text as='b' fontSize='15px'>Total Working Hrs</Text>,
        //     selector: row => <Text fontSize='15px'>{row.total_working_hours}</Text>,
        //     // sortable: true,
        // },
        {
            name: <Text as='b' fontSize='15px'>Details</Text>,
            // selector: row => <Button colorScheme='teal' size='sm' onClick={() => ShowInfo(userID,row.date)}>View</Button>
            selector: row => row.meeting_hours !== '-' && row.meeting_hours !== '00:00:00' ? <ViewModal date={row.date} userID={userID} total={row.meeting_hours} /> : <Button size='sm' isDisabled={true}>view</Button>
        }
    ];

    const getdata_datewise = async () => {
        setLoading(true)
        try {
            let data = await datewisereport(format(singleDate, 'yyyy-MM-dd').toString());
            if (data.status === 200) {
                setUserData(data.data.response);
                setLoading(false)
            }
        } catch (err) {
            Navigate('/signin')
        }
    };

    const getSingleUserdata = async () => {
        setLoading(true)
        try {
            let data = await SingleUserReport(userID, start_date, to_date);
            if (data.status === 200) {
                setSingleuser(data.data.response);
                // console.log(data.data.response)
                setLoading(false)
            }
        } catch (err) {
            Navigate('/signin')
        }
    };


    useEffect(() => {
        getdata_datewise()
    }, [singleDate, userID]);

    useEffect(() => {
        getSingleUserdata()
    }, [start_date, to_date, userID]);



    return (
        <Box>
            <Box>
                <Navbar user={user} />
            </Box>
            <Box>
                <TileBar />
            </Box>

            <Box width={{ base: '100%', md: '99%' }} bg='teal.200' gap='7px' position='relative' m='auto' p='3px' borderRadius='3px'>

                <Tabs size='md' variant='enclosed' w='100%' p={{ base: '0', md: '2px' }} mt='0'>
                    <TabList>
                        <Tab>
                            <Text as='b'>Total Attendance</Text>
                        </Tab>
                        <Tab><Text as='b'>Meeting Reports</Text></Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel p={{ base: '0px', md: '5px' }} mt={{ base: '5px', md: '10px' }}>
                            <Box w='100%'>
                                <Flex gap='10px' justifyContent='left' alignItems='center' mb='10px'>

                                    <SingleDatePicker date={singleDate} setDate={setSingleDate} />
                                    {/* <Box bg='teal.400' w='fit-content'>
                                        <Dropdown handelUser={FindUser} singleDate={singleDate} userID={userID} />
                                    </Box> */}
                                    <Spacer />
                                    <ExportBtn data={userData} filename={`${format(date, 'dd-mm-yyyy').toString()}-report.csv`} />
                                </Flex>

                                {!Loading ?
                                    <DataTable
                                        columns={columns}
                                        data={userData}
                                        striped='true'
                                        pagination
                                    /> :
                                    <Loader />
                                }

                            </Box>
                        </TabPanel>
                        <TabPanel p={{ base: '0px', md: '5px' }} mt={{ base: '5px', md: '10px' }}>
                            <Flex gap='10px' justifyContent='left' alignItems='center' mb='10px'>
                                <Datepicker date={mydate} start={start_date} end={to_date} handelDate={handelDate} handelTimeline={handelTimeline} />
                                <Box bg='teal.400' w='fit-content'>
                                    <Dropdown handelUser={handelUser} />
                                </Box>
                                <Spacer />
                                {
                                    userID === '' ? <></> :
                                        <ExportBtn data={singleuser} filename={`${userID}-${format(date, 'dd-mm-yyyy').toString()}-report.csv`} />
                                }
                            </Flex>
                            {
                                userID === '' ?
                                    <Flex
                                        m='auto'
                                        justifyContent='center'
                                        h='400px' bg='teal.200'
                                        p='20px'
                                        alignItems='center'><Text as='b'>Select User</Text></Flex> :
                                    <Box h='fit-content' bg='teal.200'>
                                        {
                                            !Loading ?
                                                <DataTable
                                                    columns={colm}
                                                    data={singleuser}
                                                    striped='true'
                                                    pagination

                                                /> :
                                                <Loader />
                                        }
                                    </Box>
                            }
                        </TabPanel>
                    </TabPanels>
                </Tabs>


            </Box>


        </Box>
    )
}

export default Home