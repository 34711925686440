import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter,HashRouter} from 'react-router-dom'
import { Authantication } from './Pages/auth/authprovider/Authantication';

const root = ReactDOM.createRoot(document.getElementById('root'));
const CLIENT_ID = process.env.REACT_APP_CLINTID;

root.render(

    <ChakraProvider>
        <HashRouter basename='/' >
            <Authantication>
                <GoogleOAuthProvider clientId={CLIENT_ID}>
                    <App />
                </GoogleOAuthProvider>
            </Authantication>
        </HashRouter>
    </ChakraProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
