import axios from "axios";

let url = process.env.REACT_APP_API;
let token = process.env.REACT_APP_API_KEY;

export const getAllBDM = async () => {
    try {
        let data = await axios.get(`${url}/api/v1/User?maxSize=100&offset=0&order=asc&where[0][type]=in&userType=internal&select=salutationName,firstName,lastName,middleName,name,userName,type,usertype,rolesIds,rolesNames,mobileAppstatus,isActive,teamsIds,teamsNames,teamsColumns,title,emailAddressIsOptedOut,emailAddressIsInvalid,emailAddress,emailAddressData&orderBy=userName&where[0][attribute]=usertype&where[0][value][]=BDM`, {
            "headers": {
                "Accept": "application/json, text/javascript, /; q=0.01",
                "Accept-Language": "en-US,en;q=0.5",
                'x-api-key': token,
                // "Sec-Fetch-Mode": "no-cors",
            },
            "method": "GET"
        });
        return data
    } catch (err) {
        return err
    }
};

export const TodaysMeeting = async () => {
    try {
        let data = await axios.get(`${url}/api/v1/Meeting?select=contactName,name,accountId,accountName,assignedUserId,assignedUserName,checkintime,checkouttime,createdAt&maxSize=100&offset=0&orderBy=dateStart&order=desc&where[0][type]=today&where[0][attribute]=createdAt&where[0][dateTime]=true&where[0][timeZone]=Asia/Kolkata&where[1][type]=or&where[1][value][0][type]=notIn&where[1][value][0][attribute]=accountId&where[1][value][0][value][]=6488566363832d0d7&where[1][value][1][type]=isNull&where[1][value][1][attribute]=accountId`, {
            "headers": {
                "Accept": "application/json, text/javascript, /; q=0.01",
                "Accept-Language": "en-US,en;q=0.5",
                'x-api-key': token,
                // "Sec-Fetch-Mode": "no-cors",
            },
            "method": "GET"
        });
        //console.log(data)
        return data
    } catch (err) {
        return err
    }
};

export const sevenDaysMeet = async () => {
    try {
        let data = await axios.get(`${url}/api/v1/Meeting?select=contactName,name,accountId,accountName,assignedUserId,assignedUserName,checkintime,checkouttime,createdAt&maxSize=100&offset=0&orderBy=dateStart&order=desc&where[0][type]=lastXDays&where[0][attribute]=createdAt&where[0][value]=7&where[0][dateTime]=true&where[0][timeZone]=Asia/Kolkata&where[1][type]=or&where[1][value][0][type]=notIn&where[1][value][0][attribute]=accountId&where[1][value][0][value][]=6488566363832d0d7&where[1][value][1][type]=isNull&where[1][value][1][attribute]=accountId`, {
            "headers": {
                "Accept": "application/json, text/javascript, /; q=0.01",
                "Accept-Language": "en-US,en;q=0.5",
                'x-api-key': token,
                // "Sec-Fetch-Mode": "no-cors",
            },
            "method": "GET"
        });
        //console.log(data)
        return data
    }
    catch (err) {
        return err
    }
};

export const TotalOnsites = async (date) => {
    try {
        let data = await axios.get(`${url}/api/v1/Meeting?select=contactName,name,accountId,accountName,assignedUserId,assignedUserName,checkintime,checkouttime,createdAt&maxSize=100&offset=0&orderBy=dateStart&order=desc&where[0][type]=between&where[0][attribute]=createdAt&where[0][value][]=${date}&where[0][value][]=${date}&where[0][dateTime]=true&where[0][timeZone]=Asia/Kolkata&where[1][type]=or&where[1][value][0][type]=notIn&where[1][value][0][attribute]=accountId&where[1][value][0][value][]=6488566363832d0d7&where[1][value][1][type]=isNull&where[1][value][1][attribute]=accountId`, {
            "headers": {
                "Accept": "application/json, text/javascript, /; q=0.01",
                "Accept-Language": "en-US,en;q=0.5",
                'x-api-key': token,
                // "Sec-Fetch-Mode": "no-cors",
            },
            "method": "GET"
        });
        // console.log(data)
        return data
    }
    catch (err) {
        return err
    }
}


export const datewisereport = async (date) => {
    try {
        let data = await axios.get(`${url}/report/api/BDMMeetingReportsMultipleUsersApi.php?date=${date}`)
        return data
    } catch (err) {
        return err
    }
};


export const SingleUserReport = async (id, start, end) => {

    console.log(id,start,end,67666)

    try {
        let data = await axios.get(`${url}/report/api/BDMWiseMeetingReportApi.php?start_date=${start}&end_date=${end}&user_id=${id}`)
        return data
    } catch (err) {
        return err
    }
};


export const AuthaticateUser = async (email) => {
    try {
        let data = await axios.get(`${url}/api/v1/User?userType=internal&select=%20userName&maxSize=1&offset=0&orderBy=userName&order=asc&where[0][type]=startsWith&where[0][attribute]=emailAddress&where[0][value]=${email}&where[1][type]=linkedWith&where[1][attribute]=teams&where[1][value][]=65855a97c4a8480cf`,
            {
                "headers": {
                    "Accept": "application/json, text/javascript, /; q=0.01",
                    "Accept-Language": "en-US,en;q=0.5",
                    'x-api-key': token,
                    // "Sec-Fetch-Mode": "no-cors",
                }
            }
        )
        return data
    } catch (err) {
        return err
    }
};


// Single User Meeting without Indigi ---

export const MeetingInfo = async (id, date) => {

    // console.log(id,date)
    
// `${url}/api/v1/Meeting?select=contactName,name,accountId,accountName,assignedUserId,assignedUserName,checkintime,checkouttime,duration,createdAt&maxSize=100&offset=0&orderBy=dateStart&order=desc&where[0][type]=on&where[0][attribute]=createdAt&where[0][value]=${date}&where[0][dateTime]=true&where[0][timeZone]=Asia/Kolkata&where[1][type]=equals&where[1][attribute]=assignedUserId&where[1][value]=${id}&where[2][type]=isTrue&where[2][attribute]=meetingStaus&where[3][type]=or&where[3][value][0][type]=notEquals&where[3][value][0][attribute]=accountId&where[3][value][0][value]=6488566363832d0d7&where[3][value][1][type]=isNull&where[3][value][1][attribute]=accountId`;


let URL = `https://crm.indigitalit.com/api/v1/Meeting?select=contactName,meetingStaus,name,accountId,accountName,duration,division,dateStart,dateStartDate,assignedUserId,assignedUserName,checkintime,checkouttime,feedbackCaptured,meetingtype,createdAt,contactID&maxSize=100&offset=0&orderBy=checkintime&order=asc&where[0][type]=on&where[0][attribute]=checkintime&where[0][value]=${date}&where[0][dateTime]=true&where[0][timeZone]=Asia/Kolkata&where[1][type]=equals&where[1][attribute]=assignedUserId&where[1][value]=${id}&where[2][type]=isTrue&where[2][attribute]=meetingStaus&where[3][type]=in&where[3][attribute]=meetingtype&where[3][value][]=On Site&where[3][value][]=VC&where[4][type]=or&where[4][value][0][type]=notIn&where[4][value][0][attribute]=accountId&where[4][value][0][value][]=6488566363832d0d7&where[4][value][1][type]=isNull&where[4][value][1][attribute]=accountId`
    try {
        let data = await axios.get( URL, {
            "headers": {
                "Accept": "application/json, text/javascript, /; q=0.01",
                "Accept-Language": "en-US,en;q=0.5",
                'x-api-key': token,
                // "Sec-Fetch-Mode": "no-cors",
            },
            "method": "GET"
        });
        console.log(data.data.list)
        return data
    } catch (err) {
        return err
    }
};