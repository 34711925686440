import React from 'react';

import { Button, Box, Flex,useToast } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import { AuthaticateUser } from '../../request';

let AUTH_API = process.env.REACT_APP_API;
const GoogleBtn = () => {

    // let usertarget = JSON.parse(localStorage.getItem('user')) || {};

    let Navigate = useNavigate()
    let toast = useToast()
    
    const responseGoogle = async(response) => {
   
        const userObject = jwtDecode(response.credential);
        if (userObject.email_verified) {
            // console.log(userObject);
           let res =await AuthaticateUser(userObject.email);
           console.log(res)
             if(res.data.total){
                localStorage.setItem('user', JSON.stringify(userObject));
                Navigate('/home')
             }else{
                toast({
                    title: 'Unauthorized',
                    position: 'top',
                    isClosable: true,
                    status:'error'
                  })
             }
        }

    };

    return (
        <Flex h='100vh' m='auto' w='fit-content'>
            <Box m='auto' p='10px' bg='teal.300'>
                <GoogleLogin
                    render={(renderProps) => (

                        <Button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                        >
                            <FcGoogle /> Sign in with google
                        </Button>

                    )}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy="single_host_origin"
                />
            </Box>
        </Flex>
    )
}

export default GoogleBtn;